import React, {Component} from 'react';
import Modal from 'react-modal'
import ModalMain from "./ModalMain";

class ModalRegistroComponent extends Component {
    render() {
        return (

            <ModalMain>

            </ModalMain>
        )
    }
}

export default ModalRegistroComponent;
