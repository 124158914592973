import React, {useEffect, Fragment} from 'react';
import {EventContext} from "../Context/FirebaseContext";

import {
    DollarSign,
    Tag,
    ShoppingBag,
    MessageCircle,
    MinusCircle,
    ThumbsUp,
    MessageSquare,
    Briefcase,
    MoreHorizontal,
    Send,
    Activity,
    Anchor,
    Compass,
    Cpu,
    Slack,
    Umbrella,
    Box,
    Book
} from 'react-feather';
import configDB from '../../data/customizer/config';
import EventTimeLineComponent from "../HomeTimeLineEvent/EventTimeLineComponent";

var Knob = require('knob');// browserify require
var primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;

class TSSHomeTimeLine extends React.Component {
    render() {
        const {
            Events,
        } = this.context;

        console.log('Los Timeline',Events);


        return (

            <Fragment>
                <div>
                    <div className="card col-12">
                        <div className="card-header">
                            <h5>Actividades del Dia Mundial de Seguridad y la Salud En el Trabajo</h5>
                        </div>
                        <div className="card-body activity-scroll">
                            <div className="activity">
                                {Events && Events.map(Actividad => {
                                    return (
                                        <EventTimeLineComponent Actividad={Actividad} key={Actividad.id}/>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>

        )
    }
}

TSSHomeTimeLine.contextType = EventContext;

export default TSSHomeTimeLine;
