import React, {Component} from 'react';

class TutotialComponent extends React.Component {

    constructor({Tuto}) {
        super({Tuto});
        console.log('el prop de Tutorialcillo', this.props.Tuto);
    }
    render() {
        return (
            <div className="col-xl-4 xl-50 col-md-6">
                <div className="card features-faq product-box">
                    <div className="faq-image product-img">
                        <img className="img-fluid" src={this.props.Tuto.ImageURL} alt=""/>
                        <div className="product-hover">
                            <ul>
                                <li><a target="_blank" href={this.props.Tuto.LinkTo}><i className="icon-link"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-body">
                        <h6>{this.props.Tuto.Titulo}</h6>
                        <p>{this.props.Tuto.Detalles}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default TutotialComponent;
