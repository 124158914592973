import React, {Fragment, useState} from 'react';
import {Aperture, Archive, FileText} from "react-feather";
import errorImg from "../../assets/images/search-not-found.png";
import knowledgebaseDB from "../../data/knowledgebase/knowledgebaseDB";
import ArticulosDestacadoComponent from "./ArticulosDestacadoComponent";
import {TutosContext} from "../Context/FirebaseContext";
import TutorialesMainComponent from "./TutorialesMainComponent";
import {ArtisContext} from "../Context/FirebaseContext";
import TutotialComponent from "./TutotialComponent";


class ArticlesComponent extends React.Component {
    render() {
        const {
            ArtisElements,
        } = this.context;
        console.log('Los Articulos: ', this.context);
        return (
            <div className="row">
                {ArtisElements && ArtisElements.map(Articulo => {
                    console.log('que sale', Articulo);
                    return (
                        <ArticulosDestacadoComponent Articulo={Articulo} key={Articulo.id}/>
                    );
                })}
            </div>

        );
    }
}


ArticlesComponent.contextType = ArtisContext;

export default ArticlesComponent;
