import React, {Component} from 'react';
import {WatchEvents} from "../TSSHomeTimeline/FireBaseWatcher";
import {WatchTimeLineEvents} from "../LineaDeTiempo/TimeLineFireBaseWatcher";
import {WatchTutosElements} from "../BaseDeConocimientos/BaseDeConocimientosFireBaseWatcher";
import {WatchArticlesElementos} from "../BaseDeConocimientos/BaseDeConocimientosFireBaseWatcher";

export const EventContext = React.createContext();
export const TimeLineContext = React.createContext();
export const TutosContext = React.createContext();
export const ArtisContext = React.createContext();


//Las Funciones para WatchEvents
export class EventContextProvider extends React.Component {
    state = {
        Events: [],
    };

    componentDidMount() {
        WatchEvents((Events) => {
            this.setState({Events});
        })
    };

    render() {
        return (
            <EventContext.Provider value={this.state}>
                {this.props.children}
            </EventContext.Provider>
        )
    }
}
export const EventContextConsumer = EventContext.Consumer;


//Las Funciones para WatchTimeLine

export class TimeLineContextProvider extends React.Component {

    state = {
        TimeLineElements: [],
    };

    componentDidMount() {
        WatchTimeLineEvents((TimeLineElements) => {
            this.setState({TimeLineElements});
        })
    };

    render() {
        return (
            <TimeLineContext.Provider value={this.state}>
                {this.props.children}
            </TimeLineContext.Provider>
        )
    }
}
export const TimeLineContextConsumer = TimeLineContext.Consumer;

//Las Funciones para WatchTutps

export class TutosContextProvider extends React.Component {

    state = {
        TutosElements: [],
    };

    componentDidMount() {
        WatchTutosElements((TutosElements) => {
            this.setState({TutosElements});
        })
    };

    render() {
        return (
            <TutosContext.Provider value={this.state}>
                {this.props.children}
            </TutosContext.Provider>
        )
    }
}
export const TutosContextConsumer = TutosContext.Consumer;




//Las Funciones para WatchArticles

export class ArtisContextProvider extends React.Component {
    state = {
        ArtisElements: [],
    };

    componentDidMount() {
        WatchArticlesElementos((ArtisElements) => {
            this.setState({ArtisElements});
        })
    };

    render() {
        return (
            <ArtisContext.Provider value={this.state}>
                {this.props.children}
            </ArtisContext.Provider>
        )
    }
}

export const ArtisContextConsumer = ArtisContext.Consumer;
