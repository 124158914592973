import React, {Component, Fragment} from 'react'
import {withMediaProps} from 'react-media-player'

class CustomPlayPause extends Component {
    shouldComponentUpdate({media}) {
        return this.props.media.isPlaying !== media.isPlaying
    }

    _handlePlayPause = () => {
        this.props.media.playPause();
        this.setState(this.props);
        const Data = this.props;

        this.props.ComparaPuntos(Data);
        this.props.RandomVid();
        this.props.ActualzarData();
        console.log('hemos pausado el video', Data);
    };

    render() {
        const {className, style, media} = this.props;
        return (
            <Fragment>
                <div>
                    <button type="image" className={className} style={style} onClick={this._handlePlayPause}>
                        {media.isPlaying ?
                            <img height='110' width='100'
                                 src='https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/Recursos%2FStopCard.png?alt=media&token=e342849b-0a9e-4d3e-b089-e1ecbf201ee1'
                            /> :
                            <img height='110' width='100'
                                 src='https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/Recursos%2FStopCard.png?alt=media&token=e342849b-0a9e-4d3e-b089-e1ecbf201ee1'
                            />}
                    </button>
                </div>
            </Fragment>
        )
    }
}

export default withMediaProps(CustomPlayPause);
