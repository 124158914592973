import React from 'react';

import AskNewRoundComponent from "./AskNewRoundComponent";
import OnGameComponent from "./OnGameComponent";

const ActividadStopCard = () => {
    return (

        <div>
            <AskNewRoundComponent/>
        </div>
    )
};

export default ActividadStopCard;
