import React, { Component, Fragment } from 'react';
import { Aperture, FileText,Codepen } from "react-feather";


class ArticulosDestacadoComponent extends React.Component {

    constructor({ Articulo }) {
        super({ Articulo });
        console.log('el prop de del Articulillo es: ', this.props);

    }

    render() {
        return (
            <Fragment>
                <div className="col-xl-3 col-md-4">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="media"><Codepen className="m-r-30" />
                                        <div className="media-body">
                                            <h6 className="f-w-600">{this.props.Articulo.Titulo}</h6>
                                            <p>{this.props.Articulo.Descripcion} <a href="{this.props.Articulo.Url}">leer Más</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </Fragment>
        );
    }
}

export default ArticulosDestacadoComponent;
