import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';

const ActividadBioseguridad = () => {
  return (
    <Fragment>
      <Breadcrumb title="Actividad Bioseguridad" parent="Actividades" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h5>Actividad de Bioseguridad</h5><span>Cuanto crees que puede llegar a esparcirse las bacterias y virus si no te lavas bien las manos? quieres averiguarlo?</span>
              </div>
              <div className="card-body">
                <p>"El video nos muestra como empezaremos la actividad, nos lavaremos las manos al final para asegurarnos de estar limpios."</p>
                <iframe width="340" height="604" src="https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/home%2Factividad-bioseguridad.mp4?alt=media&token=38d9f5cb-99ad-4db1-8c8a-da4e0f32a327" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
           </div>
          <div className="col-lg-6 col-s-6">
                <div className="ribbon-wrapper card">
                  <div className="card-body">
                    <div className="ribbon ribbon-info">Materiales</div>

                    <div className="card">
                      <div className="card-body">
                        <ul className="list-group">
                          <li className="list-group-item">Talco o Pintura</li>
                          <li className="list-group-item">Agua y Jabon</li>
                          <li className="list-group-item">Elementos para probar</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
          </div>
          <div className="col-lg-6 col-s-6">
                <div className="ribbon-wrapper card">
                  <div className="card-body">
                    <div className="ribbon ribbon-success">Procedimiento</div>

                    <div className="card">
                      <div className="card-body">
                        <ul className="list-group">
                          <li className="list-group-item">Ensuciarse las manos como en el video con talco o pintura.</li>
                          <li className="list-group-item">Realice sus actividades de manera normal por 15 minutos</li>
                          <li className="list-group-item">Revise todos los lugares que visito observando todos los lugares pintados</li>
                          <li className="list-group-item">Concienticece con la velocidad de como se esparcen los virus y bacterias</li>
                          <li className="list-group-item">Lavese las manos con agua y jabon.</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
          </div>
  
        </div>
      </div>
    </Fragment >
  );
};

export default ActividadBioseguridad;