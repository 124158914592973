import React, {Fragment, Component} from 'react';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Edit, Video, Activity, Image} from 'react-feather';
import VerticalVideoComponent from "./VerticalVideoComponent";
import VerticalImageComponent from "./VerticalImageComponent";
import {TimeLineContext} from "../Context/FirebaseContext";
import EventTimeLineComponent from "../HomeTimeLineEvent/EventTimeLineComponent";


class ComponenteDeTimeLineVertical extends Component {
    render() {
        const {
            TimeLineElements,
        } = this.context;
        return (
            <Fragment>
                <VerticalTimeline layout={'1-column'}>
                    {TimeLineElements && TimeLineElements.map(Evento => {
                        return (
                            (Evento.Image) && <VerticalImageComponent Evento ={Evento} key={Evento.id}/> || <VerticalVideoComponent Evento={Evento} key={Evento.id} />
                        )
                    })}
                </VerticalTimeline>
            </Fragment>
        );
    }
}

ComponenteDeTimeLineVertical.contextType = TimeLineContext;

export default ComponenteDeTimeLineVertical;
