import React, { Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Codepen, FileText, Youtube, BookOpen, Aperture, Archive } from 'react-feather';
import knowledgebaseDB from '../../data/knowledgebase/knowledgebaseDB';
import two from '../../assets/images/faq/2.jpg';
import one from '../../assets/images/faq/1.jpg';
import three from '../../assets/images/faq/3.jpg';
import four from '../../assets/images/faq/4.jpg';
import errorImg from '../../assets/images/search-not-found.png';
import ArticlesComponent from "./ArticlesComponent";


const MainBaseDeConocimientosCompoenent = () => {

    return (
        <Fragment>
            <Breadcrumb title="Articulos de Interés" parent="Base de conocimientos" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-4 xl-50 col-sm-6">
                        <div className="card bg-primary">
                            <div className="card-body">
                                <div className="media faq-widgets">
                                    <div className="media-body">
                                        <h5>Historia del 28 de abril</h5>
                                        <p>El Día Mundial de la Seguridad y la Salud en el Trabajo que se celebra anualmente el 28 de abril promueve la prevención de los accidentes del trabajo y las enfermedades profesionales en todo el mundo. Es una campaña de sensibilización destinada a centrar la atención internacional sobre la magnitud del problema y sobre cómo la creación y la promoción de una cultura de la seguridad y la salud puede ayudar a reducir el número de muertes y lesiones relacionadas con el trabajo.</p>
                                    </div><FileText />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 xl-50 col-sm-6">
                        <div className="card bg-primary">
                            <div className="card-body">
                                <div className="media faq-widgets">
                                    <div className="media-body">
                                        <h5>OIT: Detengamos la pandemia : la seguridad y salud en el trabajo puede salvar vidas</h5>
                                        <p>Reconociendo el gran reto que enfrentan los gobiernos, empleadores, trabajadores y todas las sociedades en el mundo entero para combatir la pandemia de COVID-19, el Día Mundial de la Salud y Seguridad en el Trabajo se enfocará en abordar el brote de enfermedades infecciosas en el trabajo, prestando especial atención a la actual pandemia de COVID-19.</p>
                                    </div><BookOpen />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 xl-100">
                        <div className="card bg-primary">
                            <div className="card-body">
                                <div className="media faq-widgets">
                                    <div className="media-body">
                                        <h5>OMS: La organización del trabajo y el estrés</h5>
                                        <p>Se considera que el estrés laboral afecta negativamente a la salud psicológica y física de los trabajadores, y a la eficacia de las entidades para las que trabajan. Un buen empleador define y gestiona el trabajo de forma que puedan evitarse los factores de riesgo de estres mas frecuentes, y prevenirse, en la medida de lo posible, los problemas previsibles; llevandose a cabo en un entorno seguro, donde se promueba la salud y el desarrollo.  </p> </div><Aperture />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="header-faq">
                            <h5 className="mb-0">Articulos de Interés</h5>
                        </div>
                        <div className="row">
                            <ArticlesComponent />
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default MainBaseDeConocimientosCompoenent;
