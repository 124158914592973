import React, {useEffect, Fragment} from 'react';
import configDB from '../../data/customizer/config';



const VideoPow = () => {

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="card ">
                    <iframe width="480" height="350" src="https://www.powtoon.com/embed/fx2LLGSlA1R/"
                            frameborder="0"></iframe>
                          
                </div>

            </div>
        </Fragment>
    );
};
export default VideoPow;
