import React, {Fragment, useEffect, useState} from 'react';
import man from '../../../assets/images/dashboard/user.png'
import {Link} from 'react-router-dom';
import {Edit} from 'react-feather';
import UserNameComponent from "./UserNameComponent";

const UserPanel = (props) => {

    const [Usuario, SetUsuario] = React.useState({
        NombreDeUsuario: ''
    });


    React.useEffect(() => {
        const UserData = localStorage.getItem('user');
        if (UserData) {
            SetUsuario((JSON.parse(UserData)).name);
            // console.log('El nombre de usuario es', SetUsuario);
        }
        console.log('El Nombre del Usuario2: ', Usuario);

    }, []);
    // console.log('El Nombre del Usuario2: ', Usuario);


    const url = '';

    const Nombre = Usuario;

    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={url ? url : man} alt="#"/>
                    <div className="profile-edit">
                       
                    </div>
                </div>
       
            </div>
        </Fragment>
    );
};

export default UserPanel;
