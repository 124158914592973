import {db} from "../../data/base";

export function WatchTimeLineEvents(callback) {
    const unsub = db
        .collection('TimeLineElements')
        .onSnapshot((snapshot) => {
            const TimeLineElements = [];
            snapshot.forEach((event) => {
                const data = event.data();
                TimeLineElements.push({
                    ...data,
                    id: event.id
                });
            });
            callback(TimeLineElements);
        });
    return unsub;
}
