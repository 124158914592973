import React, { useState, Fragment, useEffect, useRef } from 'react';
import { BrowserRouter, Link } from 'react-router-dom'
import { Media, Player, controls, utils } from 'react-media-player'
import { v4 as uuidv4 } from "uuid";
import CustomPlayPause from "./CustomPlayPause";
import { db } from "../../data/base";
import firebase from "firebase";
import shuffle from 'shuffle-array'
import Swal from "sweetalert2";
import SweetAlert from "./SweetAlert";
import CovidCounter from "../CovidIndicator/CovidCounter";
import { Redirect } from "react-router-dom";

const OnGameComponent = () => {
    // estados del juego para maquina de estados finitos
    const [AlertState, SwitchAlert] = useState(false);
    const [MediaDetails, UpdateMediaDetails] = useState([]);
    const [ContestDetail, UpDatContestDetail] = useState([]);
    const [PreviousVideo, SetPreviousVideo] = (useState(''));
    const [CondicionalContent, SwitchConditionalSate] = (useState(true));

    // rounds de juego, variables de apoyo
    const [InitialMediaState, UpdateMediaState] = useState({});
    const [InitialDetails, UpdateRounDetails] = useState({
        RoundId: uuidv4(),
        RemainingRounds: 5,
        UserName: 'TestUser',
    });

    // Variables del juego
    const [Puntos, UpdatePuntos] = useState(0);
    const [Accidente, UpdateAccidente] = useState(0);
    const { CurrentTime } = controls;

    useEffect(() => {
        db.collection("StopCardMediaDetails")
            .onSnapshot((snapshot) => {
                const
                    ArrayMediaDetails = [];
                snapshot.forEach((ArrayMediaElement) => {
                    const data = ArrayMediaElement.data();
                    ArrayMediaDetails.push({
                        ...data,
                        id: ArrayMediaElement.id
                    });
                    UpdateMediaDetails(ArrayMediaDetails);


                });
                RandomElement(ArrayMediaDetails);
            })
    }, []);


    const RandomElement = (ArrayMediaDetails) => {
        console.log('esta es la ronda', InitialDetails.RemainingRounds);
        const TurnosRestantes = InitialDetails.RemainingRounds;

        // tienes todavia rounds para jugar
        if (TurnosRestantes >= 0) {
            //  y tienes videos cargados
            if (!ArrayMediaDetails) {
                // tiempo de carga entre videos
                setTimeout(() => {
                    //Ocurre cunado el parametro llega Vacio
                    const DetalleSolo = MediaDetails;
                    SetPreviousVideo(ContestDetail);
                    const CurrentVid = shuffle.pick(DetalleSolo, 1);
                    UpDatContestDetail(CurrentVid);
                    const NuevoMediaState = MediaDetails.filter(VideDetails => VideDetails.id !== CurrentVid.id);
                    UpdateMediaDetails(NuevoMediaState);
                }, 7500);


            } else {
                //Ocurre cuando se ejecuta por primera vez y el paramatro llega con datos el array cargado desde firebase
                const ArrayDetailForMedia = ArrayMediaDetails;
                const DetalleSolo = ArrayDetailForMedia.map(DetallerSolo => DetallerSolo.id);
                const CurrentVid = shuffle.pick(ArrayDetailForMedia, 1);
                SetPreviousVideo(CurrentVid);
                UpDatContestDetail(CurrentVid);
                const NuevoMediaState = ArrayDetailForMedia.filter(VideDetails => VideDetails.id !== CurrentVid.id);
                console.log('Asi tendria que quedar el State', NuevoMediaState);
                console.log('estos serian los videos a shuflear', CurrentVid.id);
                UpdateMediaDetails(NuevoMediaState);
            }
        } else {

            Swal.fire({
                title: '<strong> Muchas gracias por participar </strong>',
                html:
                    'Has evitado' + Accidente + ' Accidentes.' +
                    'Te invitamos a visitar otras secciones de esta pagina <b>secciones de esta pagina</b>, ' +
                    '<a href="/base-de-conocimientos"><b>Base de Conocimientos</b></a> ',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: false,
                // footer: (<a href={`${process.env.PUBLIC_URL}/home`}>Home</a>)
            })
        }
    };


    const ComparaPuntos = (ClickMoment) => {
        const ClickValue = ClickMoment.media.currentTime;

        console.log('Este es el Segundo capturado', ClickValue);
        const prevVid = PreviousVideo;
        console.log('este seria el video Anterior', prevVid);
        const SafeTimeStart = prevVid.SafeTimeStart;
        const SafeTimeEnd = prevVid.SafeTimeEnd;
        if
            (ClickValue < SafeTimeStart) {
            SwitchAlert(!AlertState);
            SumaAccidente();
            console.log('es menor a 5');
            console.log('Este es el Momento del click', ClickValue);
            Swal.fire({
                title: '<strong style="color: red">Oh Noo!!<u>Ocurrio un accidente!! Vuelve a intentar.</u></strong>',
                html:
                    ' <video width="320" height="240"  autoplay>\n' +
                    '  <source src="https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/VideoKoffie%2FvideoKoffie001.mp4?alt=media&token=43b04cdd-9566-498a-9d87-9f7c0d8123ff" type="video/mp4"/>' +
                    '</video>',
                timer: 6990,
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
                cancelButtonText: false,

            })
        } else if (ClickValue > SafeTimeStart && ClickValue < SafeTimeEnd) {
            SwitchAlert(!AlertState);
            console.log('Estas en tiempo Seguro');
            SumaPunto();
            Swal.fire({
                title: '<strong >Excelente!<u>Esta vez ellos no Bailan y tu evitaste un accidente!</u></strong>',
                html:
                    ' <video width="320" height="240"  autoplay>\n' +
                    '  <source src="https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/VideoKoffie%2Fezgif-1-f65669a75dd9.mp4?alt=media&token=bb6cf5c8-123a-4a93-b066-8c078fa25f87" type="video/mp4"/>' +
                    '</video>',
                timer: 6990,
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: (<Link to={`/home`} />),
                cancelButtonText: false,
            })
        } else if (ClickValue > SafeTimeEnd) {
            Swal.fire({
                title: '<strong style="color: red">Oh Noo!!<u>Ocurrio un accidente!! Vuelve a intentar.</u></strong>',
                html:
                    ' <video width="320" height="240" controls autoplay>\n' +
                    '  <source src="https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/VideoKoffie%2FvideoKoffie001.mp4?alt=media&token=43b04cdd-9566-498a-9d87-9f7c0d8123ff" type="video/mp4"/>' +
                    '</video>',
                timer: 6990,
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
                cancelButtonText: false,
            })
        }
    };


    useEffect(() => {
        const RoundValues = InitialMediaState.media;
        if (!RoundValues) {
            console.log('aun no se hizo click');
        } else {
            console.log('Con Use Effect', RoundValues);
            const ClickMoment = RoundValues.currentTime;
            console.log('El Momento del Clcik', ClickMoment);
            ComparaPuntos(ClickMoment);
        }
    });


    const SumaAccidente = () => {
        UpdateAccidente(Accidente + 1)
    };


    const SumaPunto = () => {
        UpdatePuntos(Puntos + 1)
    };


    const RestaIntento = () => {
        console.log('Quedan intentos', InitialDetails.RemainingRounds);
        UpdateRounDetails({ ...InitialDetails, RemainingRounds: InitialDetails.RemainingRounds - 1 });
    };


    return (
        <Fragment>

            <div className='row'>
                <div className='col-lg-12 col-sm-12'>
                    <Media>
                        <div className="media" width="500px">
                            <div className="media-player">
                                <Player height="250px" width="450px" src={ContestDetail.ResourceURL} autoPlay={true} />
                            </div>
                            <br />
                            <div className="media-controls">
                                <CurrentTime />
                                {/*//Pasa pro Props Los datos de funcino al componente Custom para oberner los estados*/}
                                <CustomPlayPause ActualzarData={RestaIntento}
                                    RandomVid={RandomElement}
                                    ComparaPuntos={ComparaPuntos}
                                />

                            </div>
                        </div>
                    </Media>
                </div>
                <div className='col-lg-6 col-sm-6 u-steps-xs steps-sizing-sm-mb'>
                    <div className="u-step done bg-warning">
                        <div className="u-step done bg-warning"><span
                            className="u-step-number text-warning">{InitialDetails.RemainingRounds}</span>
                            <div className="u-step-desc"><span className="u-step-title">Intentos</span></div>
                        </div>
                    </div>
                    <div className="u-step error bg-secondary">
                        <div className="u-step error bg-secondary"><span
                            className="u-step-number txt-secondary">{Puntos}</span>
                            <div className="u-step-desc"><span className="u-step-title">Puntos</span></div>
                        </div>
                    </div>
                    <div className="u-step current bg-danger ">
                        <div className="u-step current bg-danger"><span
                            className="u-step-number txt-danger">{Accidente}</span>
                            <div className="u-step-desc"><span className="u-step-title">Accidentes</span></div>
                        </div>
                    </div>

                </div>
            </div>


        </Fragment>


    );
};


export default OnGameComponent;
