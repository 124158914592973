import React, {useEffect, Fragment} from 'react';
import {
    DollarSign,
    Tag,
    ShoppingBag,
    MessageCircle,
    MinusCircle,
    ThumbsUp,
    MessageSquare,
    Briefcase,
    MoreHorizontal,
    Send,
    Activity,
    Anchor,
    Compass,
    Cpu,
    Slack,
    Umbrella,
    Box,
    Book,
    Bluetooth
} from 'react-feather';

class EventTimeLineComponent extends React.Component {

    constructor({Actividad}) {
        super({Actividad});

        console.log('el prop', this.props.Actividad.Titulo);

    }

    render() {
        return (

            <Fragment>
                <div>
                    <div className="activity">
                        <div className="media">
                            <div className="gradient-round m-r-30 gradient-line-1">
                                <Compass/>
                            </div>
                            <div className="media-body">
                                <h6>{this.props.Actividad.Titulo}<span
                                    className="pull-right f-14">28 Abr.</span>
                                </h6>
                                <p>{this.props.Actividad.Descripcion}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default EventTimeLineComponent;
