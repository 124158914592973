import {db} from "../../data/base";

export function WatchEvents(callback) {
    const unsub = db
        .collection('HomeActivities')
        .onSnapshot((snapshot) => {
            const Events = [];
            snapshot.forEach((event) => {
                const data = event.data();
                Events.push({
                    ...data,
                    id: event.id
                });
            });
            callback(Events);
        });
    return unsub;
}
