import React, {Component} from 'react';

export const UserContext = React.createContext();

//Las Funciones para WatchTimeLine

export class UserContextProvider extends React.Component {

    state = {
        UserData: [],
    };

    componentDidMount() {
        {
            this.setState({
                NickName: null,
                Registrado: false,
            });
        }
    };


    ActualizarModal=()=>{
      this.setState({
          Registrado: true
      })
    };


    render() {
        return (
            <UserContext.Provider value={{...this.state, ActualizarModal: this.ActualizarModal}}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

export const UserContextConsumer = UserContext.Consumer;















