import * as firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/auth";
import "firebase/analytics";

const app = firebase.initializeApp({
  apiKey: "AIzaSyBGixn_UhXyOB7mvCG05HyQsFaJ2aA6zdY",
    authDomain: "dmsst2020.firebaseapp.com",
    databaseURL: "https://dmsst2020.firebaseio.com",
    projectId: "dmsst2020",
    storageBucket: "dmsst2020.appspot.com",
    messagingSenderId: "81510102974",
    appId: "1:81510102974:web:546b15efeab40eee4febef",
    measurementId: "G-97BFZ5F4QE"
});
const defaultAnalytics = firebase.analytics();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new  firebase.auth.TwitterAuthProvider();
export const githubProvider = new  firebase.auth.GithubAuthProvider();
export const db =  firebase.firestore();

export default app;
