import {db} from "../../data/base";

export function WatchTutosElements(callback) {
    const unsub = db
        .collection('Tutoriales')
        .onSnapshot((snapshot) => {
            const TutosElements = [];
            snapshot.forEach((tuto) => {
                const data = tuto.data();
                TutosElements.push({
                    ...data,
                    id: tuto.id
                });
            });
            callback(TutosElements);
        });
    return unsub;
}


export function WatchArticlesElementos(callback) {
    const unsub = db
        .collection('Articulos')
        .onSnapshot((snapshot) => {
            const ArticulosElements = [];
            snapshot.forEach((Arti) => {
                const data = Arti.data();
                ArticulosElements.push({
                    ...data,
                    id: Arti.id
                });
            });
            callback(ArticulosElements);
        });
    return unsub;
}

