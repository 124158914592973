import React, {Component} from 'react';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Edit, Video, Activity, Image} from 'react-feather';

class VerticalImageComponent extends React.Component {

    constructor({Evento}) {
        super({Evento});
        console.log('el prop', this.props.Evento.Titulo);

    }


    render() {
        return (
            <VerticalTimelineElement
                className="vertical-timeline-element--education"
                date={` ${this.props.Evento.Fecha.toDate()}` }
                animate={true}
                icon={<Image/>}
            >
                <h4 className="vertical-timeline-element-subtitle">{this.props.Evento.Titulo}</h4>
                <img className="img-fluid p-t-20" src={this.props.Evento.ResourceURL}
                     alt="timelineImg2"/>
            </VerticalTimelineElement>

        );
    }
}

export default VerticalImageComponent;
