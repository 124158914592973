import React, { useState, Fragment, useEffect } from 'react';
import ChartistGraph from 'react-chartist';
import { earningData, earningOptions, staticChartData, staticChartOptions, yearChartData, yearChartOptions, admissionChartData, admissionChartOptions, htmlChartData, htmlChartOptions, phpChartData, phpChartOptions } from '../../charts-data/default'
import { Command, Navigation, DollarSign, HelpCircle, Mic, Zap } from 'react-feather';
import math from '../../assets/images/university/math-1.jpg'
import math2 from '../../assets/images/university/math-2.jpg';
import bio1 from '../../assets/images/university/bio-1.jpg';
import bio2 from '../../assets/images/university/bio-2.jpg';
import four from '../../assets/images/user/4.jpg';
import two from '../../assets/images/user/2.png';
import chart3 from '../../assets/images/university/chart-3.png'
import five from '../../assets/images/user/5.jpg';
import chart4 from '../../assets/images/university/chart-4.png';
import round from '../../assets/images/university/round.png';
import chart2 from '../../assets/images/university/chart-2.png';
import chart1 from '../../assets/images/university/chart-1.png';
import { Line } from 'react-chartjs-2';
import Calendar from 'react-calendar';
import Breadcrumb from '../common/breadcrumb';
import CountUp from 'react-countup';


const University = props => {
    const [date, setDate] = useState({
        date: new Date(),
    });

    useEffect(() => {

    }, []);

    const onChange = date => setDate({ date });
    return (
        <Fragment>
            <Breadcrumb parent="Tablero" title="Presentación" />
            <div className="container-fluid">
                <div className="row">
                <video width="100%" height="100%" controls >\n' +
                    '  <source src="https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/home%2Fdia%20de%20la%20seguridad%20(intro%202).mp4?alt=media&token=e9e7e359-2a00-4e1f-aaed-d78308789a6e" type="video/mp4"/> +
                    '</video>
                </div>
                <div className="row">
                <img height='100%' width='100%'
                                 src='https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/home%2FScreen%20Shot%202020-04-28%20at%201.55.45%20AM.png?alt=media&token=6958b531-eb0a-43e8-ac5a-b6c851a0da4e'
                            /> 
                <img height='100%' width='100%'
                                 src='https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/home%2Fclientes%203%202020.png?alt=media&token=fefb9819-1c29-481d-9019-c1f0108e68b5'
                            /> 
                </div>

             </div>
        </Fragment>
    );
}

export default University;