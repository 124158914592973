import React, { Fragment, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
import OnGameComponent from "./OnGameComponent";
import Breadcrumb from "../common/breadcrumb";


const AskNewRoundComponent = () => {
    const [NewRound, StartRound] = useState({
        EstadoDeRonda: false,
    });

    const BeginGame = () => {
        StartRound({
            EstadoDeronda: true,
        }
        );
    };


    //Conenido Condicional

    console.log('Este el estado del Juego', NewRound.EstadoDeRonda);

    const ContenidoCondicional = NewRound.EstadoDeRonda === false
        ?
        <Fragment>
            <div className='row'>
                <img height='400'
                    src="https://firebasestorage.googleapis.com/v0/b/dmsst2020.appspot.com/o/StopCardGames%2Fpagina%201.png?alt=media&token=caabf23e-1024-4e4e-ba5c-c812a64ada2e" alt="" />

            </div>
            <div className='row col-3 offset-3 center mt-4'>
                <button
                    className="btn btn-info btn-lg center" onClick={BeginGame} type="button">Nuevo Juego
                </button>
            </div>
        </Fragment>
        :
        <Fragment>
            <OnGameComponent StartRound={StartRound} />
        </Fragment>;


    return (
        <Fragment>
            <Breadcrumb parent="Actividades" title="Juego Stop Card" />

            <div className="col-sm-12 col-lg-6">
                <div className="card">
                    <div className="card-header">
                        <h5>Salva vidas usando la STOP CARD</h5><span>Pongamos a prueba tu capacidad de reaccion ante situaciones, cuando creas que la actividad deba pararse para salvar una vida, apreta la tarjeta STOP.</span>
                        <span>Usa tu telefono en modo retrato. (hechado)</span>
                    </div>
                    <div className="card-body">
                        {ContenidoCondicional}
                    </div>
                </div>
            </div>
            <div>

            </div>
        </Fragment>
    );
};

export default AskNewRoundComponent;
