import React, {Component} from 'react';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Edit, Video, Activity, Image} from 'react-feather';

class VerticalVideoComponent extends React.Component {

    constructor({Evento}) {
        super({Evento});

    }

    render() {
        return (
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={` ${this.props.Evento.Fecha.toDate()}`}
                icon={<Video/>}
            >
                <h4 className="vertical-timeline-element-subtitle">{this.props.Evento.Titulo}</h4>
                <div className="embed-responsive embed-responsive-21by9 m-t-20">
                    <iframe title="vertical-timeline" src={this.props.Evento.ResourceURL}
                            allowFullScreen></iframe>
                </div>
            </VerticalTimelineElement>
        );
    }
}

export default VerticalVideoComponent;
