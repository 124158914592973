import React, {useEffect, useState, Fragment} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const ModalMain = () => {
    const [Nickname, UpdateNickName] = useState(JSON.parse(window.localStorage.getItem('Nickname')));
    const [ShowModal, UpdateModal] = useState(JSON.parse(window.localStorage.getItem('ModalState')));

    const CheckSetter = () => {
        const LoadData = JSON.parse(window.localStorage.getItem('ModalState'));
        console.log('con esto arranca', LoadData);
        if (LoadData === null) {

        }
    };

    useEffect(() => {
        CheckSetter();
        if (ShowModal === null) {
            UpdateModal(true)
        }

        // UpdateModal(window.localStorage.getItem('ModalState'));
        //
        // console.log(ShowModal);
        // if (!ShowModal) {
        //     UpdateModal(true)
        // } else {
        //
        // }

    }, []);


    const onChangeName = (event) => {
        console.log('Escribiendo...', event.target.value);
        UpdateNickName({
            [event.target.name]: event.target.value
        });

        const DatodeNickName = event.target.value;

        console.log('Esto es lo Escrito', DatodeNickName);


    };

    const ActualizarModal = () => {
        UpdateModal(false);
        window.localStorage.setItem('ModalState', false);
        onSubmit()

    };


    const onSubmit = (event) => {
        event.preventDefault();

        console.log('almacenando Datos...', Nickname);
        UpdateNickName(Nickname);


        const NuevoDato = Nickname;

        const NuevoDato2 = ('Nickname', JSON.stringify(NuevoDato));


        window.localStorage.setItem('Nickname', NuevoDato2);

        console.log('Y el nuevo dato est', NuevoDato);
        console.log('Y el nuevo dato est2', NuevoDato2);
        UpdateModal(false);
        window.localStorage.setItem('ModalState', false);


        // window.localStorage.setItem.JSON('NickName', NuevoDato);
        // ActualizarModal();

    };

    return (

        <Fragment>
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body btn-showcase">
                                    {/* < !-- Using Form Modal --> */}
                                    <Modal isOpen={false}>
                                        <ModalHeader>Registra tu nombre</ModalHeader>
                                        <ModalBody>
                                            <div className="container">
                                                <form onSubmit={onSubmit}>
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" className="form-control" name='Nickname'
                                                               onChange={onChangeName}/>
                                                    </div>
                                                    <ModalFooter>
                                                        <button type="submit"
                                                                className="btn btn-primary btn-block">Guardar
                                                            Nombre/Apodo
                                                        </button>
                                                    </ModalFooter>
                                                </form>
                                            </div>
                                        </ModalBody>

                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
};

export default ModalMain;
