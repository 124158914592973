import React, {Component, Fragment} from 'react';
import two from "../../assets/images/faq/2.jpg";
import three from "../../assets/images/faq/3.jpg";
import four from "../../assets/images/faq/4.jpg";
import TutotialComponent from "./TutotialComponent";
import {TutosContext} from "../Context/FirebaseContext";
import VerticalImageComponent from "../LineaDeTiempo/VerticalImageComponent";
import VerticalVideoComponent from "../LineaDeTiempo/VerticalVideoComponent";
import {VerticalTimeline} from "react-vertical-timeline-component";
import Breadcrumb from "../common/breadcrumb";


class TutorialesMainComponent extends React.Component {
    render() {
        const {
            TutosElements,
        } = this.context;
        console.log('Los Tutoriales', TutosElements);

        return (
            <Fragment>
                <Breadcrumb parent="Base de Conocimientos" title="Tutoriales" />
                <div className="col-lg-12">
                    <div className="header-faq">
                        <h5 className="mb-0">Tutoriales</h5>
                    </div>
                    <div className="row">
                        {TutosElements && TutosElements.map(Tutorial => {
                            console.log('que sale', Tutorial);
                            return (
                                <TutotialComponent Tuto={Tutorial} key={Tutorial.id}/>

                            );

                        })}
                    </div>
                </div>
            </Fragment>
        );
    }
}

TutorialesMainComponent.contextType = TutosContext;

export default TutorialesMainComponent;
