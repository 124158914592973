import React, {Component, Fragment} from 'react';
import Breadcrumb from '../common/breadcrumb';
import EventoTimeLine from './EventoEnElTiempoComponent';
import {TimeLineContext} from "../Context/FirebaseContext";
import ComponenteDeTimeLineVertical from "./ComponenteDeTimeLineVertical";

// import VerticalTimelineComp from './VerticalTimeLineComponent';

class LienaDeTiempoComponent extends React.Component {
    render() {

        return (
            <Fragment>
                <Breadcrumb title="Actividades y Campañas" parent="Galerîa"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Actividades y Campañas</h5>
                                </div>
                                <div className="card-body">
                                    <ComponenteDeTimeLineVertical/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

//Asocia El State del Compoente, permitiendo pasarlo a props


export default (LienaDeTiempoComponent);

