import React, { useEffect, Fragment, useState, Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import VideoPow from "../VideoPowtoon/VideoPowtoon";
import TSSHomeTimeLine from "../TSSHomeTimeline/TimeLineComponent";

import { connect } from 'react-redux';

import {
    DollarSign,
    Tag,
    ShoppingBag,
    MessageCircle,
    MinusCircle,
    ThumbsUp,
    MessageSquare,
    Briefcase,
    MoreHorizontal,
    Send,
    Activity,
    Anchor,
    Compass,
    Cpu,
    Slack,
    Umbrella,
    Box,
    Book
} from 'react-feather';
import configDB from '../../data/customizer/config';

import ModalComponent from "../base/modalComponent";
import ModalRegistroComponent from "../ModalRegistroCoponent/ModalRegistroComponent";


var primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;


class Home extends Component {

    state = {
        Nombre: null
    };

    render() {

        return (
            <Fragment>

                <Breadcrumb parent="Tablero" title="Inicio" />
                <div className='row container'>
                </div>
                <div className='row container-fluid'>
                    <div className="col-lg-6 col-m-12 col-xs-12 xl-50">
                        <VideoPow />

                    </div>

                    <div className="col-lg-4 col-m-12  col-xs-12 xl-50">
                        <div className="card ">
                            <div className="card-header current">
                                <h5>MENSAJE DEL TEAM QHSE</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div>
                                        <div className="u-steps u-steps-vertical">
                                            <div className="u-step"><span className="u-step-number">Q</span>
                                                <div className="u-step-desc"><span className="u-step-title">Quedate en casa.</span>
                                                    <p>Por aquellos a quienes amamos.</p>
                                                </div>
                                            </div>
                                            <div className="u-step "><span className="u-step-number">H</span>
                                                <div className="u-step-desc"><span className="u-step-title">Higiene de las manos.</span>
                                                    <p>Para cuidarte y cuidar a los demás.</p>
                                                </div>
                                            </div>
                                            <div className="u-step"><span className="u-step-number">S</span>
                                                <div className="u-step-desc"><span className="u-step-title">Saluda con el codo.</span>
                                                    <p>Distanciamiento social, pero no fraternal. </p>
                                                </div>
                                            </div>
                                            <div className="u-step"><span className="u-step-number">E</span>
                                                <div className="u-step-desc"><span className="u-step-title">Estamos en esto juntos.</span>
                                                    <p>Mis acciones responsables, pueden salvarte la vida.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-xs-12">
                        <TSSHomeTimeLine />
                    </div>
                </div>
                <ModalRegistroComponent />



            </Fragment >

        )
    }
}


export default (Home);
